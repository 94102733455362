<template>
  <!-- 快速选择 -->
  <el-dialog
    title="快速选择"
    :visible.sync="onSelection"
    width="500px"
    :append-to-body="true"
    :close-on-click-modal="false"
    class="custem-dialog"
    v-dialogDrag
    v-disable-contextmenu
    @close="closeDialog"
  >
    <div
      class="dialog-body-box  text-container cad-select_container ksxz-container"
    >
      <div class="cad-select_wrapper" v-stopdrag>
        <div class="block-wrapper" v-closeSelect>
          <div class="flex align-start text-item">
            <div classs="block-title" style="width: 90px">
              应用到(Y):
            </div>
            <el-select
              v-model="selectionData.yyc"
              placeholder="请选择"
              ref="refKsxz0"
            >
              <el-option
                v-for="(item, idx) in yydList"
                :key="idx"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="flex align-start text-item">
            <div classs="block-title" style="width: 90px">
              对象类型(B):
            </div>
            <el-select
              v-model="selectionData.dxType"
              placeholder="请选择"
              @change="changeDxType"
              ref="refKsxz1"
            >
              <el-option
                v-for="(item, idx) in dxTypeList"
                :key="idx"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="flex align-start text-item">
            <div classs="block-title" style="width: 90px">
              特性(B):
            </div>
            <el-select
              v-model="selectionData.tx"
              ref="refKsxz2"
              :placeholder="
                !selectionData.dxType ? '请先选择对象类型' : '请选择特性'
              "
              @change="changeTx"
              :disabled="!selectionData.dxType"
            >
              <el-option
                v-for="(item, idx) in txList"
                :key="idx"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="flex align-start text-item">
            <div classs="block-title" style="width: 90px">
              运算符(Q):
            </div>
            <el-select
              v-model="selectionData.ysf"
              placeholder="请选择运算符"
              @change="changeYsf"
              ref="refKsxz3"
            >
              <el-option
                v-for="(item, idx) in ysfList"
                :key="idx"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="flex align-start text-item">
            <div classs="block-title" style="width: 90px">
              值(Y):
            </div>
            <el-select
              v-if="valType == 'select'"
              filterable
              v-model="selectionData.value"
              :placeholder="!selectionData.tx ? '请先选择特性' : '请选择'"
              :disabled="!selectionData.tx"
              ref="refKsxz4"
            >
              <el-option
                v-for="(item, idx) in valList"
                :key="idx"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <div v-else-if="valType == 'color'" style="flex:1;">
              <selectColor
                v-model="selectionData.value"
                :color="colorVal"
                ref="ksxzColor"
                @callBackColor="val => callBackKsxzColor(val)"
              ></selectColor>
            </div>
            <div v-else class="ksxz-input">
              <el-input
                v-model="selectionData.value"
                :placeholder="!selectionData.tx ? '请先选择特性' : '请输入值'"
                :disabled="!selectionData.tx"
              />
            </div>
          </div>
        </div>
        <div class="selection-radio-box">
          如何应用：
          <el-radio-group v-model="selectionData.application">
            <el-radio :label="true">包括在新选择集中(I)</el-radio>
            <el-radio :label="false">排除在新选择集之外(E)</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="flex-center block-footer"
      style="width:100%;justify-content:space-between"
    >
      <el-checkbox v-model="selectionData.additional"
        >附加到当前选择集(A)</el-checkbox
      >
      <div>
        <el-button
          @click="onSelection = false"
          size="nomal"
          native-type="button"
          >取消</el-button
        >
        <el-button @click="sureSelction" type="primary" size="nomal"
          >确定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { CommandFactory } from "@/cadplugins/map/commandfactory";
import selectColor from "@/components/cadComponents/selectColor"; //颜色选择
import { dialogMixin } from "@/utils/dialogMixin";
export default {
  name: "ksxzItem",
  mixins: [dialogMixin],
  components: { selectColor },
  props: {
    pixiMap: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      onSelection: false,
      yydList: [],
      dxTypeList: [],
      txList: [],
      ysfList: [],
      valType: "color",
      valList: [],
      selectionData: {
        yyc: "all",
        application: true,
        additional: false
      },
      //列表
      widths: [],
      styleList: [],
      bzStyleList: [],
      blockNames: [],
      ksLines: [],
      ksLayers: [],
      linetypescale: [],
      linewidths: [],
      colorVal: ""
    };
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {
    // 唤起弹框
    showDialog() {
      this.resetSelectionData();
      let getLayerInfo = this.pixiMap.getLayerInfo();
      let attributeArr = this.pixiMap.select_command._select_features;
      let linetypesname = this.pixiMap.dwgservice.getLinetypesName();

      this.styleList = Array.from(
        this.pixiMap.dwgservice._textStyles.keys()
      ).map(item => {
        return {
          value: item,
          name: item
        };
      });
      this.bzStyleList = Array.from(
        this.pixiMap.dwgservice._dimStyle.keys()
      ).map(it => {
        return {
          name: it,
          value: it
        };
      });
      let blockNames = this.pixiMap.dwgservice._blocks;
      this.blockNames = Array.from(blockNames.keys()).map(item => {
        return {
          name: item,
          value: item
        };
      });
      if (linetypesname && linetypesname.length > 0)
        this.ksLines = linetypesname.map(item => {
          return {
            name: item,
            value: item
          };
        });
      if (
        this.pixiMap &&
        this.pixiMap.getLayers() &&
        this.pixiMap.getLayers().length > 0
      ) {
        this.pixiMap.getLayers().map(item => {
          this.ksLayers.push({
            name: item._name,
            value: item._name
          });
        });
      }
      if (getLayerInfo.entities && getLayerInfo.entities.length > 0) {
        getLayerInfo.entities.map(item => {
          this.dxTypeList.push({
            name: item,
            value: item
          });
        });

        getLayerInfo.linetypescales.map(item => {
          if (item)
            this.linetypescale.push({
              name: item,
              value: item
            });
        });

        getLayerInfo.lineWidth.map(item => {
          this.linewidths.push({
            name: item,
            value: item
          });
        });
      }
      if (attributeArr && attributeArr.length > 0) {
        let attri_feature = attributeArr[0];
        let geometry = attri_feature._geometry;
        // 颜色值
        this.colorVal = attri_feature.getValue("color") || "bylayer";
      }
      this.txList = [
        {
          name: "颜色",
          value: "color",
          type: "color",
          val: this.colorVal
        }
      ];
      if (this.ksLayers.length > 0) {
        this.txList.push({
          name: "图层",
          value: "entitylayer",
          type: "select",
          list: this.ksLayers
        });
      }
      if (this.ksLines.length > 0) {
        this.txList.push({
          name: "线型",
          value: "linetype",
          type: "select",
          list: this.ksLines
        });
      }
      if (this.linetypescale.length > 0) {
        this.txList.push({
          name: "线型比例",
          value: "linetypescale",
          type: "select",
          list: this.linetypescale
        });
      }
      if (this.linewidths.length > 0) {
        this.txList.push({
          name: "线宽",
          value: "width",
          type: "select",
          list: this.linewidths
        });
      }
      console.log(
        "showDialog",
        this.yydList, //图层
        this.dxTypeList, //对象类型
        this.bzStyleList, //标注
        this.styleList, //字体
        this.ksLines, //线型
        this.blockNames, //块名
        this.linetypescale, //线型比例
        this.linewidths //线宽
      );
      this.onSelection = true;
    },
    // 对象类型选择
    changeDxType(val) {
      this.txList = [
        {
          name: "颜色",
          value: "color",
          type: "color",
          val: this.colorVal
        },
        {
          name: "图层",
          value: "entitylayer",
          type: "select",
          list: this.ksLayers
        },
        {
          name: "线型",
          value: "linetype",
          type: "select",
          list: this.ksLines
        },
        {
          name: "线型比例",
          value: "entitylayerBl",
          type: "select",
          list: this.linetypescale
        },
        {
          name: "线宽",
          value: "width",
          type: "select",
          list: this.linewidths
        }
      ];
      let arr = [];
      if (val == "块参照") {
        arr = [
          {
            name: "块的名称",
            value: "blockname",
            type: "select",
            list: this.blockNames
          }
        ];
      } else if (val == "文本") {
        arr = [
          {
            name: "内容",
            value: "content",
            type: "input"
          },
          {
            name: "样式",
            value: "textfont",
            type: "select",
            list: this.styleList
          },
          {
            name: "高度",
            value: "theight",
            type: "input"
          },
          {
            name: "旋转",
            value: "rotate",
            type: "input"
          }
        ];
      } else if (val == "标注") {
        arr = [
          {
            name: "标注样式",
            value: "_style",
            type: "select",
            list: this.bzStyleList
          }
        ];
      }
      this.txList.unshift(...arr);
      this.selectionData.tx = "color";
      this.selectionData.value = "";
      this.valType = "color";
      console.log("changeDxType", val, this.txList, this.valType);
    },
    // 特性选择
    changeTx(val) {
      this.valType = "input";
      this.selectionData.value = "";
      this.txList.some(item => {
        if (item.value == val) {
          if (item.type == "select") {
            this.valList = item.list || [];
            if (this.valList.length > 0) this.valType = item.type;
          } else if (item.type == "color") {
            this.valType = item.type;
            this._colorInfo = {
              color: "bylayer",
              index: -1,
              aci: true
            };
            this.selectionData.value = this.colorIndex || -1;
            this.colorVal = "#fff";
          }
          return true;
        }
      });
      console.log(
        "changeTx",
        val,
        this.valType,
        this.valList,
        this.selectionData.value
      );
    },
    // 运算符选择
    changeYsf(val) {
      console.log("changeYsf", val);
    },
    // 选择颜色
    callBackKsxzColor(val) {
      this.selectionData.value = val.index;
      this._colorInfo = {
        color: val.value,
        index: val.index,
        aci: true
      };
    },
    // 快选选择确定
    sureSelction() {
      console.log("sureSelction", this.selectionData);
      if (!this.selectionData.yyc) {
        this.$message("应用到(Y)不能为空!!!");
        return;
      }
      if (!this.selectionData.dxType) {
        this.$message("对象类型(B):不能为空!!!");
        return;
      }
      if (!this.selectionData.tx) {
        this.$message("特性(B)不能为空!!!");
        return;
      }
      if (!this.selectionData.ysf) {
        this.$message("运算符(Q)不能为空!!!");
        return;
      }
      if (!this.selectionData.value) {
        this.$message("值(Y)不能为空!!!");
        return;
      }
      let data = JSON.parse(JSON.stringify(this.selectionData));
      if (this.valType == "color") data._colorInfo = this._colorInfo;
      console.log("sureSelction", data);
      CommandFactory.getInstance().execCommand("qselect", this.pixiMap, data);
      this.onSelection = false;
    },
    resetSelectionData() {
      this.onSelection = false;
      this.selectionData = {
        yyc: "all",
        dxType: "all",
        tx: "",
        ysf: "",
        value: "",
        application: true,
        additional: false
      };
      this.colorVal = "bylayer";
      this.colorIndex = -1;
      this.widths = [];
      this.styleList = [];
      this.bzStyleList = [];
      this.blockNames = [];
      this.ksLines = [];
      this.ksLayers = [];
      this.linetypescale = [];
      this.linewidths = [];
      let widths = [
        "默认",
        "0.00mm",
        "0.05mm",
        "0.09mm",
        "0.13mm",
        "0.15mm",
        "0.18mm",
        "0.20mm",
        "0.25mm",
        "0.30mm",
        "0.90mm",
        "1.00mm",
        "1.06mm",
        "1.20mm",
        "1.40mm",
        "1.58mm",
        "2.00mm",
        "2.11mm"
      ];
      widths.map(width => {
        this.widths.push({
          value: width,
          name: width
        });
      });
      this.valList = [];
      this.yydList = [
        {
          name: "整个图形",
          value: "all"
        }
      ];
      this.dxTypeList = [
        {
          name: "所有图元",
          value: "all"
        }
      ];
      this.txList = [];
      this.ysfList = [
        // {
        //   name: "全部",
        //   value: "all"
        // },
        {
          name: "≠ 不等于",
          value: "≠"
        },
        {
          name: "= 等于",
          value: "="
        },
        {
          name: "> 大于",
          value: ">"
        },
        {
          name: "< 小于",
          value: "<"
        }
      ];
      this.valType = "";
      this._colorInfo = {
        color: "bylayer",
        index: -1,
        aci: true
      };
    }
  },
  watch: {}
};
</script>
<style lang="stylus" scoped>
@import '~@/assets/styl/cad'
</style>
